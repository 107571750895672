// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PhoneLink from "components/Links/PhoneLink";
import EmailLink from "components/Links/EmailLink";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";


function Contact() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox maxWidth={1000} margin={"auto"} mt={5} >
        <MDTypography variant="h5">
        Zadzwoń do nas
        </MDTypography>
        <PhoneLink label="500 262 850" link="tel:500262850" color="maintext" mt={1} />
        <PhoneLink label="789 020 593" link="tel:789020593" color="maintext" mt={1} />
        <MDTypography variant="h5" mt={2}>
        Napisz do nas
        </MDTypography>
        <EmailLink label="przedszkole.rekrutacja@op.pl" link="mailto:przedszkole.rekrutacja@op.pl" color="maintext" mt={1} />
        <MDTypography variant="h5" mt={2}>
          Kontakt do konkretnych przedszkoli
        </MDTypography>
        <Grid container spacing={3} alignItems="stretch" mt={3}>
          <Grid item xs={12} md={6} lg={4} style={{display: 'flex'}}>
            <SimpleBlogCard
              action={{
                type: "internal",
                route: "/",
                // facebook: isMobile ? "fb://profile/100057277366805" : "https://www.facebook.com/profile.php?id=100057277366805",
                maps: 'https://goo.gl/maps/KfVcbzART4yzBiG57',
                phone: "tel:618963159",
                color: "lusowo",
                // label: "Dowiedz się więcej"
              }}
              title="Lusowo"
              address='ul. Skośna 9, 62-080 Lusowo'
              phone="61 896 31 59"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{display: 'flex'}}>
            <SimpleBlogCard
              action={{
                type: "internal",
                route: "/",
                // facebook: isMobile ? "fb://profile/100057439989980" : "https://www.facebook.com/profile.php?id=100057439989980",
                maps: "https://goo.gl/maps/WYyFRtZSwuy4xJnQ6",
                phone: "tel:618164988",
                color: "tarnowo",
                // label: "Dowiedz się więcej"
              }}
              title="Tarnowo Podgórne"
              address="ul. Z. Romaszewskiego 4, 62-080 Tarnowo Podgóme"
              phone= "61 816 49 88"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{display: 'flex'}}>
            <SimpleBlogCard
              action={{
                type: "internal",
                route: "/",
                // facebook: isMobile ? "fb://profile/100057468578732" : "https://www.facebook.com/profile.php?id=100057468578732",
                maps: "https://goo.gl/maps/C9UZJTgocPAa3Zrz8",
                phone: "tel:616523928",
                color: "przezmierowo",
                // label: "Dowiedz się więcej"
              }}
              title="Przeźmierowo"
              address="ul. Składowa 45, 62-081 Przeźmierowo"
              phone= "61 652 39 28"
            />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Contact;
