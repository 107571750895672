import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { NavLink } from "react-router-dom";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarRow,
  navbarMobileMenu,
  navbarButtonsMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";

import logo from "assets/newGraphics/logo-transparent.png";


function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [logoSize, setLogoSize] = useState('big');
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function convertRemToPixels(rem) {    
      return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    }
  
    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      if (fixedNavbar && window.scrollY <= 0) {
        setLogoSize('big');
        setTransparentNavbar(dispatch, true || !fixedNavbar);
      } else if (document.body.offsetHeight > window.innerHeight + convertRemToPixels(7)) {
        setLogoSize('small');
        setTransparentNavbar(dispatch, false || !fixedNavbar);
      }
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });


  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar>
        <Grid container spacing={2} marginLeft={3}>
          <Grid item xl={4} xs={12} sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between'}}>
              <MDBox component={NavLink} to="/">
                <MDBox px={{xs: 0, md: 2}} py={1} >
                  <MDBox
                    component="img"
                    src={logo}
                    alt="Logo"
                    width={logoSize==='big'?'10rem':'3rem'}
                    sx={{transition: 'width 0.3s'}}
                    display="flex"
                  />
                </MDBox>
              </MDBox>
              <IconButton
                size="medium"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="large">
                  {"menu"}
                </Icon>
              </IconButton>
            </MDBox>
          </Grid>

          <Grid item spacing={2} xl={8} xs={1} sx={navbarButtonsMenu} pr={{xs: 3, xl: 15}}>
            <MDBox component={NavLink} to="/">
              <MDButton variant="navbarButton">
                Strona główna
              </MDButton>
            </MDBox>
            <MDBox component={NavLink} to="/aktualnosci">
              <MDButton variant="navbarButton">
                Aktualności
              </MDButton>
            </MDBox>
            <MDBox component={NavLink} to="/oprzedszkolu">
              <MDButton variant="navbarButton">
                O przedszkolu
              </MDButton>
            </MDBox>
            <MDBox component={NavLink} to="/kontakt">
              <MDButton variant="navbarButton">
                Kontakt
              </MDButton>
            </MDBox>
            <MDBox component={NavLink} to="/zapisy">
              <MDButton variant="navbarButton">
                Zapisy - cały rok
              </MDButton>
            </MDBox>
            <MDBox component={NavLink} to="/rekrutacja">
              <MDButton variant="navbarButton">
                Rekrutacja 2024/25
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
