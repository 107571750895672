// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PhoneLink from "components/Links/PhoneLink";
import EmailLink from "components/Links/EmailLink";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import "./privacy.module.css";

function Privacy() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox maxWidth={1000} margin={"auto"} mt={5} >
        <MDBox>
        <MDTypography variant="h2" mb={2}>
            Polityka Prywatności
        </MDTypography>
        <p>Poniższa Polityka Prywatności opisuje kwestie związane z korzystaniem ze strony internetowej chatkakubusiapuchatka.pl, dostępnej pod adresem https://chatkakubusiapuchatka.pl/ (dalej jako: „Strona”).</p>

        <p>Naszym celem jest zapewnienie Państwu poczucia bezpieczeństwa na naszej Stronie, dlatego też Państwa prywatność i ochrona danych osobowych są dla nas bardzo ważne. Prosimy o staranne zapoznanie się z poniższym podsumowaniem, z którego dowiedzą się Państwo w jaki sposób działa nasza Strona. Zapewniamy, że Państwa dane będą przetwarzane w sposób przejrzysty i uczciwy, oraz że dołożymy wszelkich starań, aby były traktowane z zachowaniem ostrożności i odpowiedzialnie.</p>

        <p>Poniższa Polityka prywatności ma na celu poinformowanie o sposobie wykorzystywania przez nas Państwa danych osobowych, względem których spełniamy wszystkie wymogi Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 UE z dnia 27 kwietnia 2016 r. (dalej jako: „RODO”).</p>
        <ol>
            <MDTypography variant="h4" mt={2}>
                <li>Dane Administratora</li>
            </MDTypography>

            Administratorem Państwa danych osobowych w rozumieniu RODO, jest Przedszkole Publiczne „Chatka Kubusia Puchatka” z siedzibą w Lusowie (62-080), przy ul. Skośna 9, adres do korespondencji: Lusowo ul. Skośna 9, 62-080 Tarnowo Podgórne (dalej jako: „Administrator”). W sprawach dotyczących ochrony danych osobowych można się skontaktować z Administratorem także za pośrednictwem adresu email przedszkole.rekrutacja@op.pl.

            <MDTypography variant="h4" mt={2}>
                <li>Cele i podstawy prawne przetwarzania danych</li>
            </MDTypography>

            Państwa dane osobowe mogą być przetwarzane przez Administratora na podstawie:

            <ul>
                <li>niezbędności do wykonania umowy lub do podjęcia działań przed jej zawarciem (art. 6 ust. 1 lit. b RODO), odrębnie dla celu korzystania z formularza zgłoszeniowego do rekrutacji;</li>
                <li>prawnie uzasadnionego interesu Administratora (art. 6 ust. 1 lit. f RODO), odrębnie dla celu:</li>
                <ul>
                    <li style={{ marginLeft: '50px' }}>korzystania z formularza zgłoszeniowego do rekrutacji udostępnionego przez Administratora na Portalu, gdzie uzasadnionym interesem administratora jest opieka nad użytkownikiem Portalu oraz wykorzystanie przekazanych danych w celu kontaktu z użytkownikiem Portalu w sprawach związanych z rekrutacją;</li>
                    <li style={{ marginLeft: '50px' }}>obrony przed ewentualnymi roszczeniami, gdzie uzasadnionym interesem Administratora jest dochodzenie lub obrona roszczeń.</li>
                </ul>
            </ul>
            
            <MDTypography variant="h4" mt={2}>
                <li>Przekazywanie danych osobowych</li>
            </MDTypography>

            Dane mogą być przekazane podmiotom przetwarzającym je na nasze zlecenie na podstawie umów zawartych z Administratorem, ale tylko w celu i zakresie niezbędnym dla realizacji ww. celów, w tym m.in. podmiotom świadczącym na naszą rzecz usługi IT lub inne usługi zapewniające prawidłowe funkcjonowanie Portalu, przy czym takie podmioty przetwarzają dane wyłącznie zgodnie z poleceniami Administratora.

            Przekazujemy Państwa dane wyłącznie podmiotom umiejscowionym w ramach Europejskiego Obszaru Gospodarczego i przez to podlegającym surowym przepisom unijnym o ochronie danych, lub takim, które wiąże odpowiedni standard bezpieczeństwa. Przekazywanie danych do krajów trzecich nie jest aktualnie przez nas praktykowane lub planowane.

            <MDTypography variant="h4" mt={2}>
                <li>Okres przechowywania danych osobowych</li>
            </MDTypography>

            Przetwarzamy Państwa dane osobowe jedynie przez okres niezbędny do spełnienia celu ich przechowywania lub dopóki jest to od nas wymagane zgodnie z prawem, w szczególności do momentu przedawnienia ewentualnych roszczeń lub wygaśnięcia obowiązku archiwizacji wynikającego z przepisów prawa, w tym obowiązku dotyczącego przechowywania dokumentów księgowych.

            W przypadku przetwarzania danych osobowych w celu świadczenia usługi korzystania z formularza zgłoszeniowego do rekrutacji, Państwa dane osobowe będą przetwarzane przez okres niezbędny do świadczenia tej usługi.

            W razie przetwarzania danych osobowych w oparciu o wyrażoną przez Państwa zgodę, Państwa dane będą przechowywane do momentu jej wycofania. W każdej chwili istnieje możliwość wycofania zgód wyrażonych w serwisie. Odwołanie zgody na przetwarzanie nie będzie miało wpływu na zgodność z prawem przetwarzania, którego dokonano przed jej wycofaniem.

            W przypadku przetwarzania Państwa danych na podstawie prawnie uzasadnionego interesu administratora, Administrator przestanie wcześniej przetwarzać dane jeżeli zgłosi Pani/Pan skutecznie sprzeciw wobec przetwarzania danych.

            <MDTypography variant="h4" mt={2}>
                <li>Przysługujące prawa</li>
            </MDTypography>
            <ul>
                <li style={{ marginLeft: 0, textDecoration: 'underline' }}>Prawo dostępu do danych</li>

                Mają Państwo prawo uzyskać informację odnośnie przechowywanych przez nas danych osobowych na Państwa temat, w tym kopię tych danych.

                <li style={{ marginLeft: 0, textDecoration: 'underline' }}>Prawo do sprostowania danych</li>

                Mają Państwo prawo żądania sprostowania Państwa danych osobowych, które są nieprawidłowe. Z uwzględnieniem celów przetwarzania mają Państwo prawo żądania uzupełnienia niekompletnych danych osobowych, w tym poprzez przedstawienie dodatkowego oświadczenia.

                <li style={{ marginLeft: 0, textDecoration: 'underline' }}>Prawo do usunięcia danych</li>

                Mają Państwo prawo domagać się usunięcia Państwa danych osobowych przechowywanych przez nas w następujących przypadkach:
                <ul>
                    <li style={{ marginLeft: '50px' }}>Państwa dane osobowe nie są już niezbędne do celów, w których zostały zebrane,</li>
                    <li style={{ marginLeft: '50px' }}>cofnęliście Państwo zgodę, na której opiera się przetwarzanie i nie ma innej podstawy prawnej przetwarzania,</li>
                    <li style={{ marginLeft: '50px' }}>wnieśliście Państwo sprzeciw wobec przetwarzania i nie występują nadrzędne prawnie uzasadnione podstawy przetwarzania lub sprzeciw dotyczy przetwarzania danych w celach marketingu bezpośredniego,</li>
                    <li style={{ marginLeft: '50px' }}>dane osobowe były przetwarzane niezgodnie z prawem,</li>
                    <li style={{ marginLeft: '50px' }}>dane osobowe muszą zostać usunięte w celu wywiązania się z obowiązku prawnego przewidzianego w prawie Unii lub prawie krajowym,</li>
                    <li style={{ marginLeft: '50px' }}>dane osobowe zostały zebrane w związku z oferowaniem usług społeczeństwa informacyjnego, o których mowa w art. 8 ust. 1 RODO.</li>
                </ul>

                <li style={{ marginLeft: 0, textDecoration: 'underline' }}>Prawo do przenoszenia danych</li>

                Mają Państwo prawo otrzymać w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego dane osobowe dotyczące Państwa, które nam dostarczyliście, jeżeli przetwarzanie tych danych odbywa się na podstawie zgody lub umowy oraz w sposób zautomatyzowany.
                Jeżeli domagają się Państwo przesłania tych danych do innego administratora danych, zostanie to zrealizowane, o ile istnieje taka możliwość techniczna.

                <li style={{ marginLeft: 0, textDecoration: 'underline' }}>Prawo ograniczenia przetwarzania danych</li>

                Mają Państwo prawo zażądać, aby przetwarzanie Państwa danych osobowych zostało ograniczone, w następujących przypadkach:

                <ul>
                    <li style={{ marginLeft: '50px' }}>kwestionują Państwo prawidłowość danych osobowych – na okres pozwalający nam sprawdzić prawidłowość tych danych,</li>
                    <li style={{ marginLeft: '50px' }}>przetwarzanie jest niezgodne z prawem, a Państwo sprzeciwiacie się usunięciu danych osobowych, żądając w zamian ograniczenia ich wykorzystywania,</li>
                    <li style={{ marginLeft: '50px' }}>nie potrzebujemy już danych osobowych do celów przetwarzania, ale są one Państwu potrzebne do ustalenia, dochodzenia lub obrony roszczeń,</li>
                    <li style={{ marginLeft: '50px' }}>wnieśliście Państwo sprzeciw na mocy art. 21 ust. 1 wobec przetwarzania – do czasu stwierdzenia, czy prawnie uzasadnione podstawy po stronie administratora są nadrzędne wobec podstaw sprzeciwu.</li>
                </ul>
                
                <li style={{ marginLeft: 0, textDecoration: 'underline' }}>Prawo do wniesienia sprzeciwu wobec przetwarzania</li>

                Jeżeli Państwa dane osobowe są przetwarzane na podstawie prawnie uzasadnionego interesu administratora, mają Państwo prawo do sprzeciwu w dowolnym czasie wobec przetwarzania, zgodnie z art. 21 RODO.

                <li style={{ marginLeft: 0, textDecoration: 'underline' }}>Prawo do wycofania zgody</li>

                Mają Państwo prawo do wycofania wyrażonej zgody na przetwarzanie danych osobowych w dowolnej chwili. Odwołanie zgody na przetwarzanie nie będzie miało wpływu na zgodność z prawem przetwarzania, którego dokonano przed jej wycofaniem.

                Jeśli chcą Państwo skorzystać z przysługujących Państwu praw prosimy o przesłanie do nas wiadomości na adres: przedszkole.rekrutacja@op.pl, lub pocztą tradycyjną na adres: Lusowo ul. Skośna 9, 62-080 Tarnowo Podgórne.

                Bezpieczeństwo danych osobowych jest dla nas priorytetem, jeśli jednak uznają Państwo, że przetwarzając Państwu dane osobowe naruszamy przepisy RODO, mają Państwo prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.
            </ul>
            <MDTypography variant="h4" mt={2}>
                <li>Zmiany w Polityce prywatności</li>
            </MDTypography>

            W celu zapewnienia, iż Polityka prywatności Portalu spełnia przez cały czas aktualne wymogi nałożone w przepisach prawa, zastrzegamy sobie prawo do wnoszenia do niej zmian w dowolnym czasie. Powyższe ma również zastosowanie w przypadkach, gdy Polityka prywatności wymaga zmian w celu objęcia nią nowych lub zmienionych produktów lub usług Portalu.

        </ol>

        </MDBox><MDTypography variant="h4" mt={2}>
        Masz pytania? Zadzwoń do nas
        </MDTypography>
        <PhoneLink label="500 262 850" link="tel:500262850" color="maintext" mt={1} />
        <PhoneLink label="789 020 593" link="tel:789020593" color="maintext" mt={1} />
        <MDTypography variant="h4" mt={2}>
        Lub napisz
        </MDTypography>
        <EmailLink label="przedszkole.rekrutacja@op.pl" link="mailto:przedszkole.rekrutacja@op.pl" color="maintext" mt={1} />
      </MDBox>
    </DashboardLayout>
  );
}

export default Privacy;
