import { useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import utc from 'dayjs/plugin/utc';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import PhoneLink from "components/Links/PhoneLink";
import EmailLink from "components/Links/EmailLink";

import { useState } from "react";

import PocketBase from 'pocketbase';

function Enroll() {
  const [ form, setForm ] = useState({
    name: '',
    birth: null,
    phone: '',
  });
  const [ privacyPolicyCheckbox, setPrivacyPolicyCheckbox ] = useState(false);
  const [ consentCheckbox, setConsentCheckbox ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(false);
  const [ isSuccess, setIsSuccess ] = useState(false);
  const pb = new PocketBase('https://atom.pockethost.io/');

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);
  
  dayjs.extend(utc);
  
  const pushEnrollment = async () => {
    if (!privacyPolicyCheckbox) {
      setErrorMessage('Zaakceptuj politykę prywatności');
      return;
    }
    if (!consentCheckbox) {
      setErrorMessage('Wyraź zgodę na przetwarzanie danych osobowych');
      return;
    }
    if (form.name === '') {
      setErrorMessage('Uzupełnij imię i nazwisko dziecka');
      return;
    }
    if (form.phone === '') {
      setErrorMessage('Uzupełnij numer telefonu');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('name', form.name);
      formData.append('phone', form.phone);
      const birthDate = form.birth.add(1, 'day');
      formData.append('birth', birthDate);
      formData.append('privacyPolicyConsent', privacyPolicyCheckbox);
      formData.append('dataProcessingConsent', consentCheckbox);
      formData.append('privacyPolicyConsentDate', dayjs(new Date()));
      formData.append('dataProcessingConsentDate', dayjs(new Date()));
      
      await pb.collection('enrollments').create(formData).then((res) => {
        setIsSuccess(true);
        setErrorMessage('');
      });
    }
    catch (e) {
      if (Object.keys(e.data.data).includes('name')) {
        setErrorMessage('Uzupełnij imię i nazwisko');
      }
      else if (Object.keys(e.data.data).includes('phone')) {
        setErrorMessage('Uzupełnij numer telefonu');
      }
      else if (Object.keys(e.data.data).includes('birth')) {
        setErrorMessage('Uzupełnij datę urodzenia dziecka');
      }
      else {
        setErrorMessage('Wystąpił problem z wysłaniem danych');
      }
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox width="100%" margin={"auto"} >
        <MDBox px={{xs: 0, md: 4, xl: 16}} mt={{xs: 3, md: 10}} mb={{xs: 3, md: 5}} textAlign={'flex-start'}>
          <MDTypography mt={2}>
            Szanowni Państwo,
          </MDTypography>
          <MDTypography mt={2}>
            <span style={{ fontWeight: 'bold', textDecoration: "underline"}}>Zapraszamy dzieci z naszej gminy oraz spoza niej w wieku 2,5 do 6 lat przez caly rok.</span> Prowadzimy zapisy dla dzieci w wieku 2,5 do 3 lat na adaptację przedszkolną. Ilość godzin, posiłków i zajęć w okresie adaptacji zależy od potrzeb i preferencji rodziców. W ciągu roku szkolnego dzieci chętnie wchodzą do zaaklimatyzowanej już grupy, w której poznają rzeczywistość przedszkolną, nasze zwyczaje i zasady. Chętniej też bawią się z rówieśnikami, którzy swobodnie czują się w grupie przedszkolnej.
          </MDTypography>
          <MDTypography mt={2} sx={{ fontWeight: 'bold', textDecoration: "underline"}}>
            Promocja dla nowozapisanych dzieci do końca maja - 2 miesiące darmowej opieki nad dzieckiem
          </MDTypography>
          <MDTypography mt={2}>
            Serdecznie zapraszamy do kontaktu i zapisów.
          </MDTypography>
          <MDTypography mt={2}>
            <EmailLink label="przedszkole.rekrutacja@op.pl" link="mailto:przedszkole.rekrutacja@op.pl" color="maintext" mt={1} />
            <PhoneLink label="500 262 850" link="tel:500262850" color="maintext" mt={1} />
            <PhoneLink label="789 020 593" link="tel:789020593" color="maintext" mt={1} />
          </MDTypography>
          <MDTypography mt={2}>
            W miarę możliwości, jeśli mamy wolne miejsce w starszych grupach, w ciągu roku szkolnego zapisujemy też dzieci w wieku od 3 do 6 lat.
          </MDTypography>
        </MDBox>
      </MDBox>

      <Card sx={{display: 'flex', maxWidth: '400px', margin: 'auto'}}>
        <MDBox pt={4} pb={3} px={3}>
          <MDTypography variant="h5" fontWeight="medium" color="#161616" mb={4}>
            Prosimy o wypełnienie formularza. Skontaktujemy się z Państwem, w sprawie zapisu dziecka do naszego przedszkola.
          </MDTypography>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput value={form.name} onChange={e => setForm({...form, name: e.target.value})} type="text" label="Imię i nazwisko dziecka" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pl'>
                <DatePicker
                  label="Data urodzenia dziecka"
                  value={form.birth}
                  onChange={(newValue) => setForm({...form, birth: newValue.local()})}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </MDBox>
            <MDBox mb={2}>
              <MDInput value={form.phone} onChange={e => setForm({...form, phone: e.target.value})} type="phone" label="Nr telefonu do rodzica/ opiekuna prawnego" fullWidth />
            </MDBox>
            <MDBox display={'flex'} flexDirection="column" gap={2}>
              <Link href="http://chatkakubusiapuchatka.pl/politykaprywatnosci" target="_blank">
                <span style={{ fontSize: 16, textDecoration: 'underline' }}>Polityka prywatności</span>
              </Link>
              <FormGroup>
                <FormControlLabel sx={{ display: 'flex', textAlign: 'justify' }} required control={
                  <Checkbox checked={privacyPolicyCheckbox} onChange={e => setPrivacyPolicyCheckbox(e.target.checked)} />
                } label="Potwierdzam, że zapoznałam/em się i akceptuję Politykę prywatności" />
              </FormGroup>
              <FormGroup mt={2}>
                <FormControlLabel sx={{ display: 'flex', textAlign: 'justify' }} required control={
                  <Checkbox checked={consentCheckbox} onChange={e => setConsentCheckbox(e.target.checked)} />
                } label="Wyrażam zgodę na przetwarzanie moich danych osobowych w celu rekrutacji do przedszkola." />
              </FormGroup>
            </MDBox>
            {errorMessage &&
              <MDBox mb={2}>
                <MDTypography variant="body2" color="error" align="center">
                  {errorMessage}
                </MDTypography>
              </MDBox>
            }
            {isSuccess &&
              <MDBox mb={2}>
                <MDTypography variant="body2" color="success" align="center">
                  Poprawnie wysłano dane
                </MDTypography>
              </MDBox>
            }
            <MDBox mt={2} display='flex' justifyContent='end' >
              <MDButton color="maintext" onClick={() => pushEnrollment()} >
                Wyślij
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default Enroll;
