// @mui material components
import Link from '@mui/material/Link';
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import PhoneLink from "components/Links/PhoneLink";

function EnrollThisYear() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox width="100%" margin={"auto"} >
        <MDBox px={{xs: 0, md: 4, xl: 16}} mt={{xs: 3, md: 10}} mb={{xs: 3, md: 5}} textAlign={'flex-start'}>
          <MDTypography mt={2} sx={{ fontWeight: 'bold' }}>
            Rekrutacja do Przedszkola Publicznego Chatka Kubusia Puchatka na rok 2024/2025
          </MDTypography>
          <MDTypography mt={2}>
            Od dnia 19 lutego 2024 do 15 marca 2024 w naszych przedszkolach przyjmowane będą wnioski o przyjęcie dziecka i objęcie edukacją przedszkolną w następnym roku szkolnym.
          </MDTypography>
          <MDTypography mt={2}>
            {'Wnioski wraz z załącznikami będą dostępne w przedszkolu w formie papierowej, gotowe do wypełnienia. Zgłoszenia można też dokonać przez stronę internetową '}
            <MuiLink display={'inline-block'} sx={{overflowWrap: "break-word"}} maxWidth={'100%'} href={'http://www.nabor.pcss.pl/tarnowopodgorne/'} target="_blank" rel="noreferrer">
              <MDTypography sx={{ fontWeight: 'bold' }}> 
                {'http://www.nabor.pcss.pl/tarnowopodgorne/,'}
              </MDTypography>
            </MuiLink>
            {' a wypełnione, wydrukowane i podpisane dokumenty dostarczyć do naszego przedszkola.'}
          </MDTypography>
          <MDTypography mt={2}>
            W sprawie rekrutacji, pytań związanych z funkcjonowaniem naszego przedszkola prosimy o kontakt telefoniczny -
          </MDTypography>
          <MDTypography mt={2}>
            <PhoneLink label="500 262 850" link="tel:500262850" color="maintext" mt={1} />
            <PhoneLink label="789 020 593" link="tel:789020593" color="maintext" mt={1} />
          </MDTypography>
          <MDTypography mt={2}>
            W przypadku chęci zapisania dziecka młodszego, dwuipółletniego, koniecznie prosimy  skontaktować się z nami.
          </MDTypography>
          <MDTypography mt={2}>
            Poniżej zamieściliśmy dla Państwa informator:
          </MDTypography>
          <MDBox mt={3} mb={3}>
            <Link href="https://drive.google.com/file/d/15vCabjGw0NDa4y8ddCyktf5LoZ2pCp0O/view?usp=sharing" target="_blank">
              <MDButton color="maintext">
                Pobierz informator
              </MDButton>
            </Link>
          </MDBox>
          <MDTypography mt={2}>
            oraz wniosek o przyjęcie dziecka do przedszkola:
          </MDTypography>
          <MDBox mt={3} mb={3}>
            <Link href="https://drive.google.com/file/d/1N3uhu-dlEIl-98fZc9D6nsRMW8Wko5nr/view?usp=sharing" target="_blank">
              <MDButton color="maintext">
                Pobierz wniosek
              </MDButton>
            </Link>
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default EnrollThisYear;
